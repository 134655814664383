import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { NGROK } from "../../../APIs";
import axios from "axios";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const useStyles = makeStyles({
  root: {
    height: "450px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const TopUsers = ({ data, limit }) => {
  let classes = useStyles();
  const [sliceData, setSliceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserPlotData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const resourceType = item.computerUserEmail;
      const value = parseInt(item._value, 10);

      if (acc[resourceType]) {
        acc[resourceType] += value;
      } else {
        acc[resourceType] = value;
      }

      return acc;
    }, {});

    const sortedData = Object.entries(groupedData)
      .sort((a, b) => b[1] - a[1])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    const filteredData = Object.fromEntries(
      Object.entries(sortedData).slice(0, limit),
    );
    setSliceData(filteredData);
  };

  const getPercentage = (actualData, obj) => {
    let sumValue = Object.values(obj).reduce((acc, curr) => acc + curr, 0);
    return Math.round((actualData / sumValue) * 100);
  };

  useEffect(() => {
    getUserPlotData(data);
  }, [data]);

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  return (
    <div className={classes.root}>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {Object.entries(sliceData).map(([key, value], index) => (
          <div key={index}>
            <Box
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ListItem sx={{ p: 5 }}>
                <ListItemIcon>
                  <PersonOutlineOutlinedIcon />
                </ListItemIcon>

                <ListItemText>
                  <Typography variant="body1" fontWeight="bold">
                    {key}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Box
                maxWidth={100}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  spacing={2}
                >
                  <Stack spacing={2} sx={{ flex: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={getPercentage(value, sliceData)}
                    />
                  </Stack>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginLeft: 2,
                    }}
                  >
                    {getPercentage(value, sliceData)}%
                  </Typography>
                </Box>
              </Box>
            </Box>

            {index < Object.keys(sliceData).length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </div>
  );
};

export default TopUsers;
