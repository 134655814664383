import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { MoreVertical } from "react-feather";
import {
  CardContent,
  CardHeader,
  IconButton,
  Card,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../APIs";
import RefreshIcon from "@mui/icons-material/Refresh";

const ChartWrapper = styled.div`
  height: 378px;
  position: relative; /* For absolute positioning of 'No Data Found' */
`;

const NoDataMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #666;
`;

const ResoruceType = ({
  filterPagination,
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
}) => {
  const [count, setCount] = useState([]);
  const [label, setLabel] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAuditResourcesData = async (modifiedData) => {
    try {
      setLoading(true);

      const counts = Object.entries(modifiedData)
        .sort((a, b) => b[1]._value - a[1]._value)
        .reduce((acc, [key, value]) => {
          acc[value.resourceType] = value._value;
          return acc;
        }, {});

      let series = [];
      let seriesLabel = [];

      for (const [key, value] of Object.entries(counts)) {
        series.push(value);
        seriesLabel.push(key);
      }
      setCount(series);
      setLabel(seriesLabel);
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };

  const fetchAuditResources = async () => {
    try {
      if (!email || !selectedTenantName) return;
      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/overview?bucketName=${bucket}&timingMode=${filterPagination}&email=${email}&type=resourceType`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);
      let modifiedData = [];
      if (response?.status === 200) {
        console.log(response.data);
        modifiedData = response.data.map((item) => ({
          ...item,
          time: item.timestamp || item._time,
        }));
      }

      fetchAuditResourcesData(modifiedData);
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };

  useEffect(() => {
    fetchAuditResources();
  }, [
    filterPagination,
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
  ]);

  const chartData = {
    series: count,
    options: {
      chart: {
        type: "pie",
      },
      labels: label,
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value + " times";
          },
        },
      },
    },
  };
  const handleRefresh = () => {
    setLoading(true);
    fetchAuditResources();
  };

  const noData = count.length === 0 || label.length === 0;

  return (
    <Card>
      <CardHeader
        title="Resource type"
        action={
          <IconButton onClick={handleRefresh} aria-label="refresh">
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent>
        <ChartWrapper>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : noData ? (
            <NoDataMessage>No data found</NoDataMessage>
          ) : (
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="pie"
              height="100%"
            />
          )}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default ResoruceType;
