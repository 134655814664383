import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
import ReactApexChart from "react-apexcharts";
import { NGROK } from "../../../APIs";
import axios from "axios";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
  position: relative;
`;
const LolbinCharts = ({
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
  mode,
  customDate,
}) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState([]);
  const getISOWeek = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1); // Start of the year
    const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000)); // Days from start of the year
    return Math.ceil((days + startDate.getDay() + 1) / 7); // ISO Week number
  };

  const countByDate = (startDate, endDate) => {
    let dateCounts = {};
    const filteredData = data.filter((item) => {
      const timestamp = new Date(item._time);
      return timestamp >= startDate && timestamp <= endDate;
    });

    filteredData.forEach((item) => {
      const dateStr = new Date(item._time).toLocaleDateString();
      dateCounts[dateStr] =
        (dateCounts[dateStr] || 0) + parseInt(item._value, 10);
    });

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateStr = currentDate.toLocaleDateString();
      if (!dateCounts[dateStr]) {
        dateCounts[dateStr] = 0;
      }
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    const sortedCounts = Object.entries(dateCounts).sort((a, b) => {
      return new Date(a[0]) - new Date(b[0]);
    });
    return Object.fromEntries(sortedCounts);
  };

  const countByWeekInMonth = (startDate, endDate) => {
    const result = {};
    let currentDatePointer = new Date(startDate);

    const filteredData = data.filter((item) => {
      const timestamp = new Date(item._time);
      return timestamp >= startDate && timestamp <= endDate;
    });

    const getMonthWeekNumber = (date) => {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const diffInDays = Math.floor(
        (date - startOfMonth) / (1000 * 60 * 60 * 24),
      );
      return Math.floor(diffInDays / 7) + 1;
    };

    const processedDates = new Set();

    while (currentDatePointer <= endDate) {
      const year = currentDatePointer.getFullYear();
      const month = currentDatePointer.getMonth();
      const weekNumber = getMonthWeekNumber(currentDatePointer);
      const monthName = currentDatePointer
        .toLocaleString("default", { month: "short" })
        .toUpperCase();
      const weekKey = `${year}-${monthName}-W${weekNumber}`;

      console.log(
        `Checking weekKey: ${weekKey} for date ${currentDatePointer.toISOString()}`,
      );

      if (!result[weekKey]) {
        result[weekKey] = 0;
      }

      filteredData.forEach((item) => {
        const timestamp = new Date(item._time);
        const dateKey = timestamp.toISOString().split("T")[0];

        if (
          timestamp.getFullYear() === year &&
          timestamp.getMonth() === month &&
          getMonthWeekNumber(timestamp) === weekNumber &&
          !processedDates.has(dateKey)
        ) {
          console.log(
            `Adding value ${item._value} to ${weekKey} for date ${dateKey}`,
          );
          result[weekKey] += parseInt(item._value, 10);
          processedDates.add(dateKey);
        }
      });

      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }

    console.log("Final week counts: ", result);
    return result;
  };

  const processData = useCallback(
    (data) => {
      let counts = {};

      if (!data || data.length === 0) {
        setNoData(true);
        setLoading(false);
        return;
      }

      setNoData(false);
      const now = new Date();
      let startDate, endDate;

      if (customMode) {
        startDate = new Date(customDate[0]);
        endDate = new Date(customDate[1]);
        counts =
          mode === "24Hrs" || mode === "lw"
            ? countByDate(startDate, endDate)
            : countByWeekInMonth(startDate, endDate);
      } else {
        switch (mode) {
          case "24Hrs":
            startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            endDate = now;
            counts = countByDate(startDate, endDate);
            break;
          case "lw":
            startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            endDate = now;
            counts = countByDate(startDate, endDate);
            break;
          case "lm":
            startDate = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the month
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the month
            counts = countByWeekInMonth(startDate, endDate);
            break;
          case "l3m":
            startDate = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() - 90,
            );
            endDate = now;
            counts = countByWeekInMonth(startDate, endDate);
            break;
          default:
            console.log("Invalid mode");
            return;
        }
      }

      if (mode === "24Hrs" || mode === "lw") {
        const labels = Object.keys(counts);
        const sortedLabels = labels.sort((a, b) => new Date(a) - new Date(b));

        const series = [
          {
            name: "Data",
            data: sortedLabels.map((label) => counts[label]),
          },
        ];

        const options = {
          chart: {
            type: "area",
            height: 350,
            stacked: true,
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          xaxis: {
            type: "category",
            categories: sortedLabels,
            labels: {
              rotate: -45,
            },
          },
          dataLabels: { enabled: false },
          yaxis: {
            labels: {
              formatter: function (value) {
                return Number.isInteger(value) ? value : "";
              },
            },
          },
        };

        setChartData({ series, options });
      } else {
        const stackedData = {
          labels: Object.keys(counts),
          datasets: [
            {
              label: "Weekly Count",
              data: Object.values(counts),
              backgroundColor: "#387ADF",
              borderColor: "#387ADF",
              stack: "Stack 1",
            },
          ],
        };

        const options = {
          chart: {
            type: "bar",
            stacked: true,
            toolbar: { show: false },
          },
          plotOptions: { bar: { horizontal: false } },
          dataLabels: { enabled: false },
          xaxis: { categories: stackedData.labels },
          fill: { opacity: 1 },
          tooltip: {
            y: {
              formatter: function (value) {
                return `${value}`;
              },
            },
          },
          legend: { show: false },
        };

        setChartData({ stackedData, options });
      }

      setLoading(false);
    },
    [data, mode, customDate, customMode],
  );

  const fetchAuditTimeSeries = async (isFirst = false) => {
    try {
      if (!email || !selectedTenantName) return;

      if (isFirst) setLoading(true);
      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/eventtimeline?bucketName=${bucket}&timingMode=${mode}&email=${email}&resourceType=LOLBin Audit`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);
      if (response?.data) {
        const modifiedData = response.data.map((item) => ({
          ...item,
          _time: item._start,
        }));
        setData([...modifiedData]);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditTimeSeries(true);
    const interval = setInterval(() => {
      fetchAuditTimeSeries();
    }, 100000);
    return () => clearInterval(interval);
  }, [
    mode,
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
  ]);

  useEffect(() => {
    processData(data);
  }, [data, processData]);

  if (loading) {
    return (
      <Card>
        <CardHeader
          action={
            <IconButton>
              <MoreVertical />
            </IconButton>
          }
          title="Events Timeline"
        />
        <CardContent>
          <ChartWrapper>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "95%",
              }}
            >
              <CircularProgress />
            </div>
          </ChartWrapper>
        </CardContent>
      </Card>
    );
  }

  if (noData) {
    return (
      <Card>
        <CardHeader
          action={
            <IconButton>
              <MoreVertical />
            </IconButton>
          }
          title="Events Timeline"
        />
        <CardContent>
          <ChartWrapper>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "95%",
              }}
            >
              <Typography>No Data Available</Typography>
            </div>
          </ChartWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardHeader title="Timeline" />
        <CardContent>
          <ChartWrapper>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series || chartData.stackedData.datasets}
              type={mode === "24Hrs" || mode === "lw" ? "area" : "bar"}
              height={350}
            />
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default LolbinCharts;
