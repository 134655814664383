import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  Box,
  Tab,
} from "@mui/material";
import RequestMessageDialog from "../../components/RequestMessageDialog";
import moment from "moment";
import useNotificationStore from "../../../services/notificationStore";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { TabContext, TabList } from "@mui/lab";

const ResourceRequestsUser = () => {
  const [tabValue, setTabValue] = useState("Whiteswan");
  const { userNotifications, setUserNotifications, userAWSNotifications } =
    useNotificationStore();
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const userData = useUserStore((state) => state.user);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/access/user-notifications-by-email?email=${userData.email}&tenantName=${latestTenant}`,
      );
      setUserNotifications(response.data);
    } catch (error) {
      console.log(error, "error fetchNotifications");
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const sortedNotification = userAWSNotifications?.sort(
    (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt),
  );

  console.log(sortedNotification);

  useEffect(() => {
    if (userData.email) fetchNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <TabContext value={tabValue}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="onPrem" value={"Whiteswan"} />
            <Tab label="AWS" value={"AWS"} />
          </TabList>
        </Box>

        <CustomTabPanel value={tabValue} index={"Whiteswan"}>
          <TableContainer
            component={Paper}
            sx={{
              border: 1,
              overflow: "hidden",
              display: "flex",
              height: "fit-content",
            }}
          >
            <Table
              sx={{
                borderCollapse: "collapse",
                borderStyle: "hidden",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Computer Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Resource Category
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Resource Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Request Message
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Privilege Level
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "16px" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userNotifications.length ? (
                  userNotifications.map((notification) => (
                    <TableRow key={notification.id}>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {moment(notification.createdAt).format(
                          "YYYY/MM/DD/HH:mm",
                        )}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {notification.computerName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {notification.resourceType === "GROUP" &&
                        notification.resourceName === "Whiteswan Access"
                          ? "SESSION"
                          : notification.resourceType}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {notification.resourceName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        <RequestMessageDialog
                          messageText={notification.requestMessage}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {notification.privilegeLevel === "PERMANENT" ? (
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            PERMANENT
                          </Typography>
                        ) : (
                          <Stack spacing={2}>
                            <Typography
                              fontWeight={500}
                              fontSize={16}
                              variant="body1"
                            >
                              DYNAMIC
                            </Typography>
                            <Typography
                              fontWeight={500}
                              fontSize={16}
                              variant="body1"
                            >
                              {notification.selectedTime} min
                            </Typography>
                          </Stack>
                        )}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        <Button
                          variant="outlined"
                          color={
                            notification.requestStatus === "APPROVED"
                              ? "success"
                              : "error"
                          }
                        >
                          {notification.requestStatus === "DECLINE"
                            ? "DECLINED"
                            : notification.requestStatus}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography variant="body1">
                        No resource request found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={"AWS"}>
          <TableContainer
            component={Paper}
            sx={{
              border: 1,
              overflow: "hidden",
              display: "flex",
              height: "fit-content",
            }}
          >
            <Table
              sx={{
                borderCollapse: "collapse",
                borderStyle: "hidden",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Requested By
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    ARN
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Category
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Resource Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Permission
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Request Message
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    Privilege Level
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedNotification.length ? (
                  sortedNotification.map((notification) => (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                          }}
                          align="center"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {moment(notification.createdAt).format(
                              "YYYY/MM/DD/HH:mm",
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                          }}
                          align="left"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {notification.email}
                          </Typography>
                          <Typography fontSize={14} variant="subtitle2">
                            <strong>AWS UserName:</strong>{" "}
                            {notification.awsUsername}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                          }}
                          align="center"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {notification.accessARN}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                          }}
                          align="center"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {notification.accessType}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                            maxWidth: "350px",
                          }}
                          align="center"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {notification.accessName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                            maxWidth: "350px",
                          }}
                          align="center"
                        >
                          <Typography fontSize={16} variant="subtitle2">
                            {notification.selectedPolicies !== null
                              ? notification.selectedPolicies
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          <RequestMessageDialog
                            messageText={notification.requestMessage}
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          {notification.privilegeLevel === "PERMANENT" ? (
                            <Typography
                              fontWeight={500}
                              fontSize={16}
                              variant="body1"
                            >
                              PERMANENT
                            </Typography>
                          ) : (
                            <Stack spacing={2}>
                              <Typography
                                fontWeight={500}
                                fontSize={16}
                                variant="body1"
                              >
                                DYNAMIC
                              </Typography>
                              <Typography
                                fontWeight={500}
                                fontSize={16}
                                variant="body1"
                              >
                                {notification.selectedTime} min
                              </Typography>
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          <Button
                            variant="outlined"
                            color={
                              notification.requestStatus === "APPROVED"
                                ? "success"
                                : "error"
                            }
                          >
                            {notification.requestStatus === "DECLINE"
                              ? "DECLINED"
                              : notification.requestStatus}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography variant="body1">
                        No Resource Request found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
      </TabContext>
    </>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default ResourceRequestsUser;
