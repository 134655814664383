import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";

import { TabContext, TabPanel } from "@mui/lab";
import TopResources from "./TopResources";
import TopUsers from "./TopUsers";
import { NGROK } from "../../../APIs";
import axios from "axios";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const TopComputer = ({
  filterPagination,
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
  data,
  topuser,
  topResourceRefresh,
}) => {
  const [value, setValue] = useState("users");
  const [auditData, setAuditData] = useState(null);
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getComputerPlotData = (data) => {
    if (data) {
      const computers = data.map((item) => {
        if (item?.computerName?.length > 0) {
          return {
            computerName: item.computerName,
            value: parseInt(item._value, 10) || 0,
          };
        } else {
          return { computerName: "default", value: 0 };
        }
      });

      const computerGroup = computers.reduce((acc, { computerName, value }) => {
        acc[computerName] = (acc[computerName] || 0) + value;
        return acc;
      }, {});

      const sortedData = Object.entries(computerGroup)
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      const series = [
        {
          name: "",
          data: Object.values(sortedData),
        },
      ];

      const options = {
        chart: {
          type: "bar",
          stacked: true,

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: Object.keys(sortedData),
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value + " times";
            },
          },
        },
      };
      setAuditData(data);
      setSeries(series);
      setOptions(options);
      setLoading(false);
    }
  };

  const fetchAuditComputer = async (isFirst = false) => {
    try {
      if (!email || !selectedTenantName) return;
      if (isFirst) setLoading(true);
      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/overview?bucketName=${bucket}&timingMode=${filterPagination}&email=${email}&type=computerName`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);

      const modifiedData = response.data.map((item) => ({
        ...item,
        time: item.timestamp || item._time,
      }));

      getComputerPlotData(modifiedData);
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };
  const handleRefresh = () => {
    fetchAuditComputer(true);
    topResourceRefresh();
  };

  useEffect(() => {
    fetchAuditComputer(true);
  }, [
    filterPagination,
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
  ]);

  return (
    <>
      <TabContext value={value}>
        <Card>
          <CardHeader
            title={
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                {!fromDetails && (
                  <Tab value="computers" label="Top Computers" />
                )}
                <Tab value="users" label="Top Users" />
                <Tab value="resources" label="Top Resources" />
              </Tabs>
            }
            action={
              <IconButton onClick={handleRefresh} aria-label="refresh">
                <RefreshIcon />
              </IconButton>
            }
          />
          <CardContent>
            <ChartWrapper>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                auditData !== null && (
                  <>
                    <TabPanel value="computers">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="bar"
                          height="100%"
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="resources">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopResources data={data} limit={5} />
                      </Box>
                    </TabPanel>
                    <TabPanel value="users">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopUsers data={topuser} limit={5} />
                      </Box>
                    </TabPanel>
                  </>
                )
              )}
            </ChartWrapper>
          </CardContent>
        </Card>
      </TabContext>
    </>
  );
};

export default TopComputer;
