import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Pagination,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { BsFiletypeExe } from "react-icons/bs";
import { formatDateTime } from "../../../GenericMethods";
import { NGROK } from "../../../APIs";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 885px;
`;
const useStyles = makeStyles({
  root: {
    height: "855px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const TimeLineSeries = ({
  activityCount,
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
  mode,
}) => {
  const classes = useStyles();
  const [recentActivityData, setRecentActivityData] = useState(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [totalFetchedData, setTotalFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [lastTime, setLastTime] = useState(null);
  const [reachedEnd, setReachedEnd] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const startIndex = (newPage - 1) * 10;
    const endIndex = newPage * 10;
    const newData = totalFetchedData.slice(startIndex, endIndex);
    setRecentActivityData(newData);
  };

  const getActivityData = async (isFirstCall = false) => {
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled due to new request.");
      }

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      if (!email || !selectedTenantName) return;

      setLoading(true);

      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${mode}&email=${email}&resourceType=LOLBin Audit`;

      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }

      url += "&limitNeeded=false";

      if (selectedTenantName) {
        url += `&tenantName=${selectedTenantName}`;
      }

      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const firstPageResponse = await axios.get(url + "&pageNo=1&limit=1000", {
        cancelToken: source.token,
      });

      let allData = [];
      if (firstPageResponse?.data) {
        allData = [...allData, ...firstPageResponse.data];
      }

      setTotalFetchedData(allData);
      setRecentActivityData(allData.slice(0, 10));
      setLoading(false);
      setLastTime(allData[allData.length - 1]?._time);
    } catch (e) {
      console.log("Error fetching data:", e);
      if (axios.isCancel(e)) {
        console.log("Request canceled:", e.message);
      }
      setLoading(false);
    }
  };

  const urlWithPage = async (page, cancelTokenSource, lastTime) => {
    if (!email || !selectedTenantName) return;

    if (!lastTime) {
      console.log("No lastTime available");
      return;
    }
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let bucket = "ondevice-auditlog";
    let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${mode}&email=${email}&resourceType=LOLBin Audit`;

    if (customMode) {
      url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
    }

    url += "&limitNeeded=false";

    if (selectedTenantName) {
      url += `&tenantName=${selectedTenantName}`;
    }

    if (fromDetails) {
      url += `&fromdetails=true&computerName=${computerName}`;
    }

    const newUrl = `${url}&pageNo=${page}&limit=10&recentstartTime=${lastTime}`;
    try {
      const response = await axios.get(newUrl, {
        cancelToken: source.token,
      });
      if (response?.status === 200 && response?.data.length > 0) {
        setTotalFetchedData((prevData) => [...prevData, ...response.data]);
        const lastItem = response.data[response.data.length - 1];
        if (lastItem?._time) {
          setLastTime(lastItem._time);
        }
      } else {
        setReachedEnd(true);
      }
      const pagesCount = Math.min(Math.ceil(totalFetchedData.length / 10), 50);
      if (pagesCount === 50) {
        setReachedEnd(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.log("Error fetching additional data:", error);
      }
      setReachedEnd(true);
    }
    axios
      .get(newUrl, { cancelToken: cancelTokenSource.token })
      .then((response) => {
        if (response?.data) {
          setTotalFetchedData((prevData) => [...prevData, ...response.data]);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.log("Error fetching additional data:", error);
        }
      });
  };

  const handleRefresh = () => {
    setPage(1);
    getActivityData(true);
  };
  /*
  useEffect(() => {
    if (lastTime && !reachedEnd) {
      urlWithPage(page, cancelTokenSource, lastTime);
    }
  }, [lastTime, page]);
  */

  useEffect(() => {
    getActivityData(true);
  }, [
    mode,
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
  ]);

  const maxPages = Math.min(Math.ceil(activityCount / 10), 100);

  return (
    <>
      <Card>
        <CardHeader
          title="Recent LOLBin Activity"
          action={
            <IconButton onClick={handleRefresh} aria-label="refresh">
              <RefreshIcon />
            </IconButton>
          }
        />
        <CardContent>
          <ChartWrapper>
            {recentActivityData !== null && (
              <Timeline
                className={classes.root}
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.1,
                  },
                }}
              >
                {recentActivityData.map((dataVal, index) => (
                  <TimelineItem key={index} sx={{ minHeight: 200 }}>
                    <TimelineOppositeContent
                      sx={{}}
                      variant="body1"
                      color="text.secondary"
                    >
                      {formatDateTime(
                        dataVal.timestamp !== undefined &&
                          dataVal.timestamp !== null
                          ? dataVal.timestamp
                          : dataVal._time,
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <HistoryIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        minWidth: 560,
                        width: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      <Box>
                        <Typography variant="body1" fontWeight="bold">
                          {dataVal?.message}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexDirection: "column",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <ComputerOutlinedIcon />
                              <div style={{ marginLeft: 5 }}>
                                {dataVal?.computerName?.length > 0
                                  ? dataVal?.computerName
                                  : "localhost"}
                              </div>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PersonOutlineOutlinedIcon />
                              <div style={{ marginLeft: 5 }}>
                                {dataVal?.computerUserEmail?.length > 0
                                  ? dataVal?.computerUserEmail
                                  : "localhost"}
                              </div>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <BsFiletypeExe style={{ fontSize: "1rem" }} />
                              <div style={{ marginLeft: 5 }}>
                                {dataVal?.resourceName?.length > 0
                                  ? dataVal?.resourceName
                                  : "localhost"}
                              </div>
                            </Box>
                          </Box>
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            )}
            <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              {maxPages > 1 && (
                <Pagination
                  count={maxPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                />
              )}
            </Box>
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default TimeLineSeries;
