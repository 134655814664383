import { TabContext, TabPanel } from "@mui/lab";
import {
  Card as MuiCard,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import TopUsers from "../Default/TopUsers";
import ReactApexChart from "react-apexcharts";
import TopResources from "../Default/TopResources";
import { NGROK } from "../../../APIs";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const TopMachinesData = ({
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
  mode,
}) => {
  const [value, setValue] = useState("lolbin");
  const [auditData, setAuditData] = useState(null);
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState([]);
  const [topusers, setTopUsers] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchAllData = (data) => {
    if (data) {
      const sortedData = Object.entries(data)
        .sort((a, b) => b[1]._value - a[1]._value)
        .reduce((acc, [key, value]) => {
          acc[value.computerName] = value._value;
          return acc;
        }, {});

      const series = [
        {
          name: "",
          data: Object.values(sortedData),
        },
      ];

      const options = {
        chart: {
          type: "bar",
          stacked: true,

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: Object.keys(sortedData),
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value + " times";
            },
          },
        },
      };
      setAuditData(data);
      setSeries(series);
      setOptions(options);
      setLoading(false);
    }
  };

  const fetchAuditComputer = async (isFirst = false) => {
    try {
      if (!email || !selectedTenantName) return;

      if (isFirst) setLoading(true);
      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/overview?bucketName=${bucket}&timingMode=${mode}&email=${email}&type=computerName&resourceType=LOLBin Audit`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);

      const modifiedData = response.data.map((item) => ({
        ...item,
        time: item.timestamp || item._start,
      }));

      fetchAllData(modifiedData);
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };

  const fetchAuditResources = async () => {
    try {
      if (!email || !selectedTenantName) return;

      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/overview?bucketName=${bucket}&timingMode=${mode}&email=${email}&type=resourceName&resourceType=LOLBin Audit`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);

      const modifiedData = response.data.map((item) => ({
        ...item,
        time: item.timestamp || item._time,
      }));

      setResource(modifiedData);
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };
  const fetchTopUsers = async () => {
    try {
      if (!email || !selectedTenantName) return;

      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/overview?bucketName=${bucket}&timingMode=${mode}&email=${email}&type=computerUserEmail&resourceType=LOLBin Audit`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const response = await axios.get(url);

      const modifiedData = response.data.map((item) => ({
        ...item,
        time: item.timestamp || item._time,
      }));
      setTopUsers(modifiedData);
    } catch (e) {}
  };

  const handleRefresh = () => {
    fetchAuditComputer();
    fetchAuditResources();
    fetchTopUsers();
  };

  useEffect(() => {
    fetchAuditComputer();
    fetchAuditResources();
    fetchTopUsers();
  }, [
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
    mode,
  ]);

  return (
    <>
      <TabContext value={value}>
        <Card>
          <CardHeader
            title={
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="lolbin" label="Top LoLBins " />

                <Tab value="users" label="Top Users" />
                {!fromDetails ? (
                  <Tab value="computers" label="Top Computers" />
                ) : null}
              </Tabs>
            }
            action={
              <IconButton onClick={handleRefresh} aria-label="refresh">
                <RefreshIcon />
              </IconButton>
            }
          />
          <CardContent>
            <ChartWrapper>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                auditData !== null && (
                  <>
                    <TabPanel value="lolbin">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopResources data={resource} limit={10} />
                      </Box>
                    </TabPanel>
                    <TabPanel value="computers">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="bar"
                          height="100%"
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="users">
                      <Box
                        sx={{
                          height: 378,
                          margin: 0,
                        }}
                      >
                        <TopUsers data={topusers} limit={10} />
                      </Box>
                    </TabPanel>
                  </>
                )
              )}
            </ChartWrapper>
          </CardContent>
        </Card>
      </TabContext>
    </>
  );
};

export default TopMachinesData;
