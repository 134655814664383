import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import TimeLineSeries from "./TimeLineSeries";
import { myLocalStorage } from "../../../components/StorageHelper";
import TopMachinesData from "./TopMachinesData";
import LolbinCharts from "./LolbinCharts";

const LOLBin = ({ fromDetails = false, computerName = null }) => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [filterPagination, setFilterPagination] = useState("lw");
  const [customMode, setCustomMode] = useState(false);
  const [startEndDate, setStartEndDate] = useState([dayjs(), dayjs()]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [tenantList, setTenantList] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);

  const handleChangeDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };
  const dataPagination = [
    { label: "Last 24 hours", value: "24Hrs" },
    { label: "Last 7 days ", value: "lw" },
    { label: "Last 30 days", value: "lm" },
    { label: "Last 90 days", value: "l3m" },
    { label: "Custom Range", value: "customDate" },
  ];
  const handleFilterPagination = (val) => {
    setAuditLogs([]);
    setCustomMode(false);
    if (val !== null) {
      let value = dataPagination
        .filter((data) => data.label === val)
        .map((val) => val.value);
      if (value[0] === "customDate") {
        setCustomMode(true);
        const currentDate = new Date();
        const nextWeekDate = new Date(currentDate);
        nextWeekDate.setDate(currentDate.getDate() - 7);
        handleChangeFilterDateRange([nextWeekDate, currentDate]);
      }
      setFilterPagination(value[0]);
    } else {
      setFilterPagination("lw");
    }
  };
  const handleChangeFilterDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <Stack direction={"column"} spacing={5}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          {!fromDetails && tenantList?.length > 0 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName ? selectedTenantName : " "}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                handleTenantChange(value);
              }}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
            marginTop: fromDetails ? "-75px" : "inherit",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataPagination.map((value) => value.label)}
            value={
              dataPagination
                .filter((data) => data.value === filterPagination)
                .map((val) => val.label)[0]
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Time Duration" />
            )}
            onChange={(e, value) => {
              handleFilterPagination(value);
            }}
          />
          {customMode ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Start date"
                  endText="End date"
                  maxDate={new Date()}
                  value={startEndDate}
                  onChange={handleChangeDateRange}
                  renderInput={(startProps, endProps) => (
                    <Stack direction={"row"} spacing={3}>
                      <TextField {...startProps} />
                      <TextField {...endProps} />
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </>
          ) : null}
        </Box>
      </Box>
      <Divider my={6} />
      {auditLogs !== null && (
        <Grid container spacing={5}>
          <Grid item xs={12} lg={5.5} md={5.5}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={12} md={12}>
                <LolbinCharts
                  email={userData.email}
                  customMode={customMode}
                  customStartDate={customStartDate}
                  customEndDate={customEndDate}
                  selectedTenantName={selectedTenantName}
                  fromDetails={fromDetails}
                  computerName={computerName}
                  mode={filterPagination}
                  customDate={startEndDate}
                />
              </Grid>
              <Grid item xs={12} lg={12} md={12}>
                <TopMachinesData
                  email={userData.email}
                  customMode={customMode}
                  customStartDate={customStartDate}
                  customEndDate={customEndDate}
                  selectedTenantName={selectedTenantName}
                  fromDetails={fromDetails}
                  computerName={computerName}
                  mode={filterPagination}
                  customDate={startEndDate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6.5} md={6.5}>
            <TimeLineSeries
              email={userData.email}
              customMode={customMode}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              selectedTenantName={selectedTenantName}
              fromDetails={fromDetails}
              computerName={computerName}
              mode={filterPagination}
              customDate={startEndDate}
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default LOLBin;
